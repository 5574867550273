import Shuffle from 'shufflejs';

export default {
  init() {
    // JavaScript to be fired on the home page
    
    // Shuffle

    const shuffleInstance = new Shuffle(document.getElementById('grid'), {
      itemSelector: '.masonry-panel',
      sizer: '.my-sizer-element',
    });

    $('.filters-group button').on('click', function (e) {
      e.preventDefault();
      $('.filters-group button').removeClass('text-dark');
      $(this).addClass('text-dark');
      const keyword = $(this).attr('data-group');
      shuffleInstance.filter(keyword);
    });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
