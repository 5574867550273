// core version + navigation module:
import Swiper, {
  Navigation,
  Autoplay,
} from 'swiper';

import smoothscrollPolyfill from 'smoothscroll-polyfill';

import smoothscrollAnchorPolyfill from 'smoothscroll-anchor-polyfill';

export default {
  init() {
    // JavaScript to be fired on all pages

    // Fancybox

    $('[data-fancybox]').fancybox({
      clickContent: false,
      infobar: false,
      transitionEffect: false,
      gutter: 0,
      // smallBtn: true,
      // toolbar: false,
      buttons: [
        // "zoom",
        //"share",
        // "slideShow",
        //"fullScreen",
        //"download",
        // "thumbs",
        'close',
      ],
    });

    Swiper.use([Navigation, Autoplay]);

    smoothscrollPolyfill.polyfill();

    // Swiper
    const swiper = new Swiper('.swiper-container', {
      // Optional parameters
      slidesPerView: 1,
      loop: true,
      autoplay: true,
      disableOnInteraction: false,

      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    // Video control

    $.fn.isInViewport = function () {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    setInterval(function () {
      $('video').each(function () {

        let played = $(this).attr('played');

        if ($(this).isInViewport()) {
          if (played == 'false') {
            $(this)[0].play();
            $(this).attr('played', 'true');
          }
        } else {
          $(this)[0].pause();
          $('.video-control').fadeIn();
        }
      });
    }, 1000);


    $('video').parent().click(function () {
      if ($(this).children('video').get(0).paused) {
        $(this).children('video').get(0).play();
        $(this).children('.video-control').fadeOut();
      } else {
        $(this).children('video').get(0).pause();
        $(this).children('.video-control').fadeIn();
      }
    });

    // Navbar resize/show/hide
    $(function () {
      let prevScrollpos = $(window).scrollTop();
      let hideNavBarOffsetHeight = $(window).height() * 0.3;
      let styleNavBarOffsetHeight = $(window).height() * 0;

      $(window).scroll(function () {

        // When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar
        let currentScrollPos = window.pageYOffset;

        if ($(window).scrollTop() > hideNavBarOffsetHeight) {
          if (prevScrollpos > currentScrollPos) {
            $('#nav-site').removeClass('nav-hide').addClass('position-fixed');
            $('.wrap').addClass('wrap-scrolled');
          } else {
            $('#nav-site').addClass('nav-hide');
          }
        }

        prevScrollpos = currentScrollPos;

        // When the user scrolls down 80px from the top of the document, alter navbar's styling
        if ($(window).scrollTop() > styleNavBarOffsetHeight) {
          $('#nav-site').addClass('bg-black').addClass('nav-scrolled');
          $('body').removeClass('modal-open');
        } else {
          $('#nav-site').removeClass('bg-black').removeClass('nav-scrolled').removeClass('position-fixed');
          $('.wrap').removeClass('wrap-scrolled');
          $('body').removeClass('modal-open');
        }
      });
    });

    $('#emailDownload').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget) // Button that triggered the modal
        var downloadTitle = button.data('download-title') // Extract info from data-* attributes
        var downloadId = button.data('download-id') // Extract info from data-* attributes
        // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
        // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
        var modal = $(this)
        modal.find('.modal-title span').text(downloadTitle)
        modal.find('.ebd_input input').val(downloadId)
    });

    function readingTime() {
      const text = document.getElementById('body').innerText;
      const wpm = 225;
      const words = text.trim().split(/\s+/).length;
      const time = Math.ceil(words / wpm).toString();
      document.getElementById('time').innerText = time;
    }

    if(document.getElementById('body') != null){
      readingTime();
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
