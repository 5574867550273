import Shuffle from 'shufflejs';

export default {
  init() {
    // JavaScript to be fired on the home page
    
    // Shuffle

    const shuffleInstance = new Shuffle(document.getElementById('blog'), {
      itemSelector: '.all-post-tile',
      sizer: '.my-sizer-element',
    });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
